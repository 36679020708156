/* General Styles */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
}

.App-header {
  flex-shrink: 0;
}

.logo-image {
  height: 40px; /* Adjust the height as needed */
  width: auto;
}

.hero, .about, .features {
  flex-grow: 1;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  color: white;
  background-color: black;
  border-bottom: 2px solid #c5291cff;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 15px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #c5291cff;
}

.hero {
  background-color: #2c2c2c;
  padding: 60px 20px;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
  color: white;
}

.hero h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 40px;
}

.cta-button {
  background-color: #c5291cff;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
}

.cta-buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.cta-button {
  background-color: #c5291cff;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #a42319;
}


.about, .features, .practice {
  padding: 40px 20px;
}

.video-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto; /* Center the video */
  padding-top: 20px; /* Space above the video */
}

.video-container iframe {
  width: 100%;
  height: 450px;
  border-radius: 8px; /* Optional: Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Subtle shadow */
}

/* Carousel Styles */
.carousel {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Subtle shadow */
  text-align: center;
}

.carousel-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}

.carousel-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.carousel-text {
  flex: 1;
  padding-right: 20px; /* Space between text and image */
}

.carousel-image {
  flex: 1;
  text-align: right;
}

.carousel-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  max-height: 300px;
}

.carousel-controls {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.carousel-control {
  background-color: #c5291cff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.carousel-control:hover {
  background-color: #a42319;
}

.carousel-control.prev {
  margin-left: 10px;
}

.carousel-control.next {
  margin-right: 10px;
}
